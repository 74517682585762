import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconUnfoldMore(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m16 15l-4 4l-4-4m0-6l4-4l4 4"
			/>
		</Icon>
	);
}
